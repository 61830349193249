// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { iconStyle } from './IconNew.style'

type PropsType = {
  as: string,
  color?: string,
  onClick?: () => void,
  size?: 'lg' | 'md' | 'sm',
}

const IconNew = (props: PropsType) => {
  const { as: Component, onClick } = props

  const { css } = useFela({ ...props })

  return (
    <Component 
      className={css(iconStyle)} 
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet"
    />
  )
}

export default IconNew
